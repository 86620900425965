var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.line_item
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "el-row",
            { attrs: { gutter: 12 } },
            [
              _c("el-col", { staticClass: "info", attrs: { lg: 12, sm: 12 } }, [
                _c("span", { staticClass: "description" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.show_invoice_id
                          ? _vm.line_item.id
                          : _vm.line_item.description
                      ) +
                      " "
                  ),
                ]),
                _c("div", { staticClass: "info" }, [
                  _c("label", [_vm._v("Amount:")]),
                  _c("span", [
                    _vm._v(
                      _vm._s(_vm._f("currency")(_vm.line_item.line_amount))
                    ),
                  ]),
                  _c("label", [_vm._v("Unit:")]),
                  _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("currency")(_vm.line_item.unit_amount)) +
                        " "
                    ),
                    _vm.line_item.discount_rate
                      ? _c("small", [
                          _vm._v(
                            " ( -" +
                              _vm._s(_vm.line_item.discount_rate) +
                              "% ) "
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c("label", [_vm._v("(prices ex. GST)")]),
                ]),
                _c("div", { staticClass: "info" }, [
                  _c("label", [_vm._v("Quantity:")]),
                  _c("span", [_vm._v(_vm._s(_vm.line_item.quantity))]),
                ]),
                _c(
                  "div",
                  { staticClass: "info" },
                  [
                    _c("label", [_vm._v("Rate:")]),
                    _c("span", { staticClass: "flex" }, [
                      _c("b", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.lineItem_rate(_vm.line_item).toFixed(2)
                              )
                            ) +
                            " "
                        ),
                      ]),
                      _c("i", [_vm._v("|")]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.lineItem_rate_type(_vm.line_item)) +
                          " "
                      ),
                      _vm.lineItem_rate_type(_vm.line_item) ===
                      _vm.RATE_UNIT.DAY
                        ? _c("small", [
                            _vm._v(
                              " ( " +
                                _vm._s(
                                  _vm.lineItem_daily_hours(_vm.line_item)
                                ) +
                                "h ) "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _c(
                      "el-popover",
                      {
                        attrs: { placement: "top" },
                        model: {
                          value: _vm.override.rate.visible,
                          callback: function ($$v) {
                            _vm.$set(_vm.override.rate, "visible", $$v)
                          },
                          expression: "override.rate.visible",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "popover" },
                          [
                            _c(
                              "el-form",
                              {
                                attrs: { size: "mini" },
                                nativeOn: {
                                  submit: function ($event) {
                                    $event.preventDefault()
                                    return _vm.setRate.apply(null, arguments)
                                  },
                                },
                              },
                              [
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "money",
                                      _vm._b(
                                        {
                                          staticClass: "el-input__inner",
                                          model: {
                                            value: _vm.override.rate.value,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.override.rate,
                                                "value",
                                                $$v
                                              )
                                            },
                                            expression: "override.rate.value",
                                          },
                                        },
                                        "money",
                                        _vm.money,
                                        false
                                      )
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        on: { click: _vm.setDefaultRateValue },
                                      },
                                      [_vm._v(" Default ")]
                                    ),
                                  ],
                                  1
                                ),
                                _vm.override.rate.type === _vm.RATE_UNIT.DAY
                                  ? _c(
                                      "el-form-item",
                                      [
                                        _c("el-input-number", {
                                          attrs: {
                                            size: "mini",
                                            "controls-position": "right",
                                          },
                                          model: {
                                            value:
                                              _vm.override.rate.daily_hours,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.override.rate,
                                                "daily_hours",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "override.rate.daily_hours",
                                          },
                                        }),
                                        _c(
                                          "el-button",
                                          {
                                            on: {
                                              click: _vm.setCalculatedRateValue,
                                            },
                                          },
                                          [_vm._v(" Calculated ")]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "el-form-item",
                                  [
                                    _c(
                                      "el-radio-group",
                                      {
                                        attrs: { size: "mini" },
                                        model: {
                                          value: _vm.override.rate.type,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.override.rate,
                                              "type",
                                              $$v
                                            )
                                          },
                                          expression: "override.rate.type",
                                        },
                                      },
                                      [
                                        _c("el-radio-button", {
                                          attrs: { label: _vm.RATE_UNIT.HOUR },
                                        }),
                                        _c("el-radio-button", {
                                          attrs: { label: _vm.RATE_UNIT.DAY },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          size: "mini",
                                          loading: _vm.override.rate.saving,
                                        },
                                        on: { click: _vm.setRate },
                                      },
                                      [_vm._v(" Save ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "span",
                          {
                            staticClass: "link",
                            attrs: { slot: "reference" },
                            slot: "reference",
                          },
                          [_vm._v(" Edit ")]
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _c("div", { staticClass: "info" }, [
                  _c("label", [_vm._v("Given time:")]),
                  _c("span", [
                    _c("b", [
                      _vm._v(
                        _vm._s(
                          _vm.lineItem_quantity(_vm.line_item).toFixed(1)
                        ) + "h"
                      ),
                    ]),
                  ]),
                ]),
                _vm.is_linked || _vm.line_item.project_ref
                  ? _c(
                      "div",
                      { staticClass: "status" },
                      [
                        _vm.is_linked
                          ? [
                              _c(
                                "div",
                                { staticClass: "default" },
                                [
                                  _c("invoice-status-icon", {
                                    staticClass: "icon",
                                    attrs: { status: _vm.INVOICE_STATUS.PAID },
                                  }),
                                  _c("span", [_vm._v(" Line item linked ")]),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "hover",
                                  on: { click: _vm.removeLink },
                                },
                                [
                                  _c("invoice-status-icon", {
                                    staticClass: "icon-error",
                                    attrs: { status: _vm.INVOICE_STATUS.ERROR },
                                  }),
                                  _c("span", [_vm._v(" Remove link ")]),
                                ],
                                1
                              ),
                            ]
                          : _vm.line_item.project_ref
                          ? [
                              _c("span", [_vm._v(" Suggestion: ")]),
                              _c(
                                "el-tag",
                                {
                                  attrs: {
                                    type: "info",
                                    "disable-transitions": true,
                                    size: "small",
                                  },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.line_item.project_ref) +
                                      " "
                                  ),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ]),
              _c(
                "el-col",
                { attrs: { lg: 12, sm: 12 } },
                [
                  _c("project-picker", {
                    attrs: { projects: _vm.projects, project: _vm.project },
                    on: { change: _vm.changedProject },
                  }),
                  _vm.project
                    ? _c("component-picker", {
                        attrs: {
                          project_id: _vm.project.id,
                          component: _vm.component,
                        },
                        on: { change: _vm.changedComponent },
                      })
                    : _vm._e(),
                  _vm.error
                    ? _c("el-alert", {
                        attrs: { description: _vm.error, closable: false },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }