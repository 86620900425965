var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.invoice
    ? _c(
        "el-form",
        {
          attrs: { "label-width": "160px" },
          nativeOn: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "Invoice number" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.show_invoice_id
                      ? _vm.invoice.id
                      : _vm.invoice.invoice_number,
                    expression:
                      "\n                show_invoice_id ? invoice.id : invoice.invoice_number\n            ",
                    arg: "copy",
                  },
                ],
                attrs: {
                  value: _vm.show_invoice_id
                    ? _vm.invoice.id
                    : _vm.invoice.invoice_number,
                  readonly: "",
                },
                nativeOn: {
                  shortkey: function ($event) {
                    _vm.show_invoice_id = !_vm.show_invoice_id
                  },
                  click: function ($event) {
                    return _vm.showNotification(
                      _vm.show_invoice_id ? "Invoice id" : "Invoice number"
                    )
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Reference" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.invoice.reference,
                    expression: "invoice.reference",
                    arg: "copy",
                  },
                ],
                attrs: { value: _vm.invoice.reference, readonly: "" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.showNotification("Reference")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Status" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.invoice.status,
                    expression: "invoice.status",
                    arg: "copy",
                  },
                ],
                attrs: { value: _vm.invoice.status, readonly: "" },
                nativeOn: {
                  click: function ($event) {
                    return _vm.showNotification("Status")
                  },
                },
              }),
            ],
            1
          ),
          _c("hr"),
          _c(
            "el-form-item",
            { attrs: { label: "Subtotal" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.invoice.sub_total,
                    expression: "invoice.sub_total",
                    arg: "copy",
                  },
                ],
                attrs: {
                  value: _vm.$options.filters.currency(_vm.invoice.sub_total),
                  readonly: "",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.showNotification("Subtotal")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "GST" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.invoice.total_tax,
                    expression: "invoice.total_tax",
                    arg: "copy",
                  },
                ],
                attrs: {
                  value: _vm.$options.filters.currency(_vm.invoice.total_tax),
                  readonly: "",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.showNotification("GST")
                  },
                },
              }),
            ],
            1
          ),
          _c("hr"),
          _c(
            "el-form-item",
            { attrs: { label: "Total" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.invoice.total,
                    expression: "invoice.total",
                    arg: "copy",
                  },
                ],
                attrs: {
                  value: _vm.$options.filters.currency(_vm.invoice.total),
                  readonly: "",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.showNotification("Total")
                  },
                },
              }),
            ],
            1
          ),
          _c("hr"),
          _c(
            "el-form-item",
            { attrs: { label: "Amount paid" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.invoice.amount_paid,
                    expression: "invoice.amount_paid",
                    arg: "copy",
                  },
                ],
                attrs: {
                  value: _vm.$options.filters.currency(_vm.invoice.amount_paid),
                  readonly: "",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.showNotification("Amount paid")
                  },
                },
              }),
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "Amount due" } },
            [
              _c("el-input", {
                directives: [
                  {
                    name: "clipboard",
                    rawName: "v-clipboard:copy",
                    value: _vm.invoice.amount_due,
                    expression: "invoice.amount_due",
                    arg: "copy",
                  },
                ],
                attrs: {
                  value: _vm.$options.filters.currency(_vm.invoice.amount_due),
                  readonly: "",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.showNotification("Amount due")
                  },
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }