var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "shortkey",
          rawName: "v-shortkey",
          value: ["alt"],
          expression: "['alt']",
        },
      ],
      staticClass: "outer",
      on: {
        shortkey: function ($event) {
          _vm.show_invoice_id = !_vm.show_invoice_id
        },
      },
    },
    [
      _c(
        "header",
        [
          _c(
            "div",
            { staticClass: "back", on: { click: _vm.goBack } },
            [
              _c("svgicon", {
                staticClass: "triangle",
                attrs: { name: "triangle" },
              }),
              _c("label", [_vm._v("BACK")]),
              _c("span", { staticClass: "spacer" }, [_vm._v("/")]),
              _c("span", [_vm._v(" " + _vm._s(_vm.invoice_number) + " ")]),
            ],
            1
          ),
          !_vm.loading && _vm.invoice
            ? [
                _vm.invoice.is_linked
                  ? _c(
                      "div",
                      { staticClass: "link-info" },
                      [
                        _c("span", [_vm._v(" This invoice is fully linked ")]),
                        _c("invoice-status-icon", {
                          attrs: { status: _vm.INVOICE_STATUS.PAID },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      { staticClass: "link-info" },
                      [
                        _c("span", [
                          _vm._v(" This invoice is missing some linking "),
                        ]),
                        _c("invoice-status-icon", {
                          staticClass: "icon-error",
                          attrs: { status: _vm.INVOICE_STATUS.ERROR },
                        }),
                      ],
                      1
                    ),
              ]
            : _vm._e(),
          _c(
            "div",
            { staticClass: "button-container" },
            [
              _vm.can_delete && !_vm.loading
                ? _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "reference",
                        type: "danger",
                        size: "mini",
                        disabled: _vm.loading,
                      },
                      on: { click: _vm.deleteInvoice },
                      slot: "reference",
                    },
                    [_vm._v(" Delete ")]
                  )
                : _vm._e(),
              _c(
                "el-button",
                {
                  attrs: { size: "mini", disabled: _vm.loading },
                  on: { click: _vm.fetchInvoice },
                },
                [_vm._v(" Refresh ")]
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm.loading
        ? _c("loader")
        : _c(
            "div",
            { staticClass: "inner" },
            [
              _vm.projects.length && _vm.sorted_line_items.length
                ? _c(
                    "content-block",
                    { attrs: { title: "Line items" } },
                    _vm._l(_vm.sorted_line_items, function (line_item) {
                      return _c("invoice-line-item-link-block", {
                        key: line_item.id,
                        attrs: {
                          line_item: line_item,
                          projects: _vm.projects,
                          show_invoice_id: _vm.show_invoice_id,
                        },
                      })
                    }),
                    1
                  )
                : !_vm.projects.length
                ? _c("el-alert", {
                    staticStyle: { "border-radius": "0" },
                    attrs: {
                      description:
                        "This invoice can't be linked, this client doesn't have any assigned projects.",
                      closable: false,
                    },
                  })
                : !_vm.sorted_line_items.length
                ? _c("el-alert", {
                    staticStyle: { "border-radius": "0" },
                    attrs: {
                      description:
                        "Something went wrong. No line items found for this invoice.",
                      closable: false,
                    },
                  })
                : _vm._e(),
              _c(
                "content-block",
                { attrs: { title: "Details" } },
                [
                  _c("invoice-form", {
                    attrs: {
                      invoice: _vm.invoice,
                      show_invoice_id: _vm.show_invoice_id,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }